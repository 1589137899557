<template>
    <div>
        <vs-button 
            v-on:click="openSubmit" 
            color="primary" 
            icon-pack="feather"
            icon="icon-inbox"
            v-if="status === 'Waiting'"
        >Submit</vs-button>
        <vs-prompt color="danger" title="Submit" :active.sync="approvePrompt" @accept="submit" @close="approvePrompt = false" :is-valid="!!id_approve.length && !id_reject.length ? true : !!id_reject.length && !!reason">
            <div>Are you sure to process for selected data(s)? Please input the reason</div><br>
            <vs-input class="w-full" placeholder="Note Reason" v-model="reason"></vs-input>
        </vs-prompt>
        <vs-table 
            search 
            stripe 
            border 
            description 
            :sst="true" 
            :data="table.data" 
            :max-items="table.length"
            :total="table.total" 
            @search="handleSearch" 
            @change-page="handleChangePage" 
            @sort="handleSort"
        >
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"></feather-icon>
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>

            <template slot="thead">
                <vs-th width="5%">Action</vs-th>
                <vs-th v-if="status === 'Waiting'">
                    <vs-checkbox @click.stop="approveAll()" v-model="statusApproveAll" color="primary"></vs-checkbox>Approve All
                </vs-th>
                <vs-th v-if="status === 'Waiting'">
                    <vs-checkbox @click.stop="rejectAll()" v-model="statusRejectAll" color="warning"></vs-checkbox>Reject All
                </vs-th>
                <vs-th sort-key="id">Sales ID</vs-th>
                <vs-th sort-key="code">Item Request ID</vs-th>
                <vs-th sort-key="date">Loading Date</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Qty (UOM)</vs-th>
                <vs-th sort-key="status">Status</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <vs-button 
                                size="small" 
                                color="warning" 
                                icon-pack="feather" 
                                icon="icon-eye"
                                v-if="status !== 'New'" 
                                title="Detail" 
                                style="margin-right: 5px"
                                @click="handleDetail(tr.id, tr.status, tr.status_picking)"
                            />
                        </div>
                    </vs-td>
                    <vs-td v-if="status === 'Waiting'">
                        <vs-checkbox
                            v-on:click="approve(indextr, tr.id)"
                            :checked="id_approve.includes(tr.id)"
                            color="primary"></vs-checkbox>
                    </vs-td>
                    <vs-td v-if="status === 'Waiting'">
                        <vs-checkbox
                            v-on:click="reject(indextr, tr.id)"
                            :checked="id_reject.includes(tr.id)"
                            color="warning"></vs-checkbox>
                    </vs-td>
                    <vs-td>{{ tr.sales_name }}</vs-td>
                    <vs-td>{{ tr.code }}</vs-td>
                    <vs-td>{{ tr.date }}</vs-td>
                    <vs-td>{{ tr.total_sku }}</vs-td>
                    <vs-td>{{ tr.quantity_uom }}</vs-td>
                    <vs-td>{{ docStatus }}</vs-td>
                    <!-- <vs-td>{{ 
                        tr.status == "0" 
                            ? "New"
                            : tr.status == "9"
                                ? "Waiting"
                                : tr.status == "1"
                                    ? "Confirmed"
                                    : tr.status == "10"
                                        ? "Rejected"
                                        : tr.status == "11"
                                            ? "Inquiry"
                                            : ["1", "2"].includes(tr.status)
                                                ? "Load-Confirm"
                                                : ["5", "6", "12"].includes(tr.status)
                                                    ? "Handovered"
                                                    : tr.status == "3"
                                                        ? "Canceled"
                                                        : tr.status == "7"
                                                            ? "Unloading"
                                                            : tr.status == "8"
                                                                ? "Close"
                                                                : tr.status == "4"
                                                                    ? "Pending"
                                                                    : ""
                                            
                    }}</vs-td> -->
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        territoryId: {
            type: Array,
        },
        warehouseId: {
            type: Array,
        },
        salesId: {
            type: Array,
        },
        status: {
            type: String,
            default: "Waiting",
        },
    },
    data() {
        return {
            deleteId: null,
            stats: null,
            stats_picking: null,
            action: null,
            table: this.tableDefaultState(),
            checked: [],
            id_item: 0,
            statusApproveAll: false,
            statusRejectAll: false,
            id_approve: [],
            id_reject: [],
            approvePrompt: false,
            reason: "",
            docStatus: "",
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }

            if (this.table.total == 0) {
                valStart = 0;
            }

            let valEnd = this.table.length * this.table.page;
            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (this.table.totalSearch < this.table.total && this.table.search != "") {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        getData() {
            let territoryIds = [];
            this.territoryId.forEach(val => {
                territoryIds.push(val.id)
            });

            let warehouseIds = [];
            this.warehouseId.forEach(val => {
                warehouseIds.push(val.ID)
            });

            let salesIds = [];
            this.salesId.forEach(val => {
                salesIds.push(val.id)
            });

            let aprStat = 0;
            if (this.status == "Waiting") {
                aprStat = 0;
                this.docStatus = "Waiting"
            } else if (this.status == "Confirmed") {
                aprStat = 1;
                this.docStatus = "Approved"
            } else if (this.status == "Rejected") {
                aprStat = 2;
                this.docStatus = "Rejected"
            } else if (this.status == "Inquiry") {
                aprStat = 11;
                this.docStatus = "Inquiry"
            }

            let param = {
                length: this.table.length,
                page: this.table.page,
                search: this.table.search,
                order: this.table.order,
                sort: this.table.sort,
                warehouse_id: warehouseIds,
                territory_id: territoryIds,
                personal_id: salesIds,
                approval_status: aprStat,
            }

            this.$vs.loading();
            this.$http
                .post("/api/v1/canvas/approval-table", param)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        handleDetail(id, status, status_picking) {
            this.$router.push({
                name: "Canvas Item Request Detail",
                params: { id: id },
            });

            /* this.stats = status
            this.stats_picking = status_picking
            if (["9", "1", "11", "10"].includes(this.stats)) {
                let aprStat = 0;
                if (this.status == "Waiting") {
                    aprStat = 0;
                } else if (this.status == "Inquiry") {
                    aprStat = 1;
                } else if (this.status == "Confirmed") {
                    aprStat = 2;
                } else if (this.status == "Rejected") {
                    aprStat = 3;
                }

                this.$router.push({
                    name: "Canvas Item Request Detail",
                    params: { id: id },
                });
            } else if (this.stats == "2") {
                if (this.stats_picking == 1) {
                    this.$router.push({
                        name: "canvas.item-request-detail-on-process",
                        params: { id: id },
                    });
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Not picked yet",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                }
            } else if (this.stats == "3") {
                this.$router.push({
                    name: "canvas.item-request-detail",
                    params: { id: id },
                });
            } */
        },
        approveAll() {
            this.statusRejectAll = false;
            if (this.statusApproveAll) {
                this.id_approve = []
            } else {
                this.id_approve = []
                this.table.data.forEach(val => {
                    this.id_approve.push(val.id)
                })
            }
            this.id_reject = [];
        },
        rejectAll() {
            this.statusApproveAll = false;
            if (this.statusRejectAll) {
                this.id_reject = []
            } else {
                this.id_reject = []
                this.table.data.forEach(val => {
                    this.id_reject.push(val.id)
                })
            }
            this.id_approve = [];
        },
        approve(index, id_item) {
            if (!this.id_approve.includes(id_item)) {
                this.id_approve.push(id_item);
            } else {
                var index2 = this.id_approve.indexOf(id_item);
                if (index2 !== -1) {
                    this.id_approve.splice(index2, 1);
                }
            }
            var index1 = this.id_reject.indexOf(id_item);
            if (index1 !== -1) {
                this.id_reject.splice(index1, 1);
            }

            if (this.id_approve.length == this.table.data.length) {
                this.statusApproveAll = true
            } else {
                this.statusApproveAll = false
            }

            if (this.id_reject.length == this.table.data.length) {
                this.statusRejectAll = true
            } else {
                this.statusRejectAll = false
            }
        },
        reject(index, id_item) {
            if (!this.id_reject.includes(id_item)) {
                this.id_reject.push(id_item);
            } else {
                var index2 = this.id_reject.indexOf(id_item);
                if (index2 !== -1) {
                    this.id_reject.splice(index2, 1);
                }
            }

            var index1 = this.id_approve.indexOf(id_item);
            if (index1 !== -1) {
                this.id_approve.splice(index1, 1);
            }

            if (this.id_reject.length == this.table.data.length) {
                this.statusRejectAll = true
            } else {
                this.statusRejectAll = false
            }

            if (this.id_approve.length == this.table.data.length) {
                this.statusApproveAll = true
            } else {
                this.statusApproveAll = false
            }
        },
        openSubmit() {
            if (this.id_approve.length == 0 && this.id_reject.length == 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Select data first",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            } else {
                this.approvePrompt = true
            }
        },
        submit() {
            if (this.id_reject.length > 0) {
                if (this.reason == null || this.reason == "") {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Rejected data(s) detected. Please fill the reason",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
    
                    return false
                }
            }

            this.$vs.loading();
            let param = {
                id_approve: this.id_approve,
                id_reject: this.id_reject,
                reason: this.reason,
            };
            this.$http.post("/api/v1/canvas/approval", param)
            .then((resp) => {
                if (resp.code == 200) {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    this.statusApproveAll = false
                    this.statusRejectAll = false
                    this.id_approve = []
                    this.id_reject = []
                    this.table.search = ""
                    this.handleSearch("")
                } else {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: error,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            });

            /* if (this.id_approve.length == 0 && this.id_reject.length == 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Select data first",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            } else {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Submit`,
                    text: "Are you sure to process for selected data(s)?",
                    accept: () => {
                        this.$vs.loading();
                        let param = {
                            id_approve: this.id_approve,
                            id_reject: this.id_reject,
                            reason: this.reason,
                        };
                        this.$http.post("/api/v1/canvas/approval", param)
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.loading.close();
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                                this.statusApproveAll = false
                                this.statusRejectAll = false
                                this.id_approve = []
                                this.id_reject = []
                                this.handleSearch("")
                            } else {
                                this.$vs.loading.close();
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: error,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        });
                    }
                })
            } */
        },
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
}
</script>