var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"vx-row ml-1 mt-3"},[_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"70%"}},[_vm._m(0),_c('div',{staticClass:"vx-col sm:w-3/4 w-full"},[_c('Territory',{on:{"selected":(val) => {
                    this.selectedTerritory = val
                }}})],1)]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"70%"}},[_vm._m(1),_c('div',{staticClass:"vx-col sm:w-3/4 w-full"},[_c('Warehouse',{on:{"selected":(val) => {
                    this.selectedWarehouse = val
                }}})],1)]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"70%"}},[_vm._m(2),_c('div',{staticClass:"vx-col sm:w-3/4 w-full"},[_c('Sales',{on:{"selected":(val) => {
                    this.selectedSales = val
                }}})],1)]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"70%"}},[_c('div',{staticClass:"vx-col sm:w-1/4 w-full flex items-center"}),_c('div',{staticClass:"vx-col sm:w-3/4 w-full"},[_c('vs-button',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleFilter()}}},[_vm._v("Filter")])],1)])]),_c('div',{staticClass:"vx-row mt-3"},[_c('div',{staticClass:"vx-col w-full mb-base"},[_c('Table',{attrs:{"draw":_vm.draw,"status":_vm.activeTab,"territory-id":this.selectedTerritory,"warehouse-id":this.selectedWarehouse,"sales-id":this.selectedSales}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col sm:w-1/4 w-full flex items-center"},[_c('span',[_vm._v("Territory")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col sm:w-1/4 w-full flex items-center"},[_c('span',[_vm._v("Warehouse")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col sm:w-1/4 w-full flex items-center"},[_c('span',[_vm._v("Sales ID")])])
}]

export { render, staticRenderFns }