<template>
    <div>
        <div class="vx-row ml-1 mt-3">
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Territory</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <Territory @selected="(val) => {
                        this.selectedTerritory = val
                    }"></Territory>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Warehouse</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <Warehouse @selected="(val) => {
                        this.selectedWarehouse = val
                    }"></Warehouse>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Sales ID</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <Sales @selected="(val) => {
                        this.selectedSales = val
                    }"></Sales>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="vx-row mt-3">
            <div class="vx-col w-full mb-base">
                <Table :draw="draw"
                    :status="activeTab"
                    :territory-id="this.selectedTerritory"
                    :warehouse-id="this.selectedWarehouse"
                    :sales-id="this.selectedSales">
                </Table>
            </div>
        </div>
    </div>
</template>

<script>
import Territory from "./component/Territory.vue";
import Warehouse from "./component/Warehouse.vue";
import Sales from "./component/Sales.vue";
import Table from "./component/Table.vue";
export default {
    components: {
        Sales,
        Territory,
        Warehouse,
        Table
    },
    data() {
        return {
            activeTab: "Inquiry",
            draw: 0,
            selectedSales: [],
            selectedTerritory: [],
            selectedWarehouse: [],
        }
    },
    methods: {
        handleFilter() {
            this.draw++;
        },
    }
}
</script>