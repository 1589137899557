<template>
    <vx-card title="Canvas - Approval">
        <div class="vx-input-group flex">
            <vs-tabs v-if="render" v-model="$route.params.tab">
                <vs-tab label="Waiting Approval">
                    <tab-waiting></tab-waiting>
                </vs-tab>
                <vs-tab label="Inquiry">
                    <tab-inquiry></tab-inquiry>
                </vs-tab>
                <vs-tab label="Approved">
                    <tab-confirmed></tab-confirmed>
                </vs-tab>
                <vs-tab label="Rejected">
                    <tab-rejected></tab-rejected>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>

<script>
import TabWaiting from "./TabWaiting.vue";
import TabInquiry from "./TabInquiry.vue";
import TabConfirmed from "./TabConfirmed.vue";
import TabRejected from "./TabRejected.vue";
export default{
    components: {
        TabWaiting,
        TabInquiry,
        TabConfirmed,
        TabRejected,
    },
    data() {
        return {
            render: true,
            activeTab: "Waiting",
        }
    },
    methods: {
        
    }
}
</script>